<template>
  <div>
    <b-alert 
      v-model="flagErr" 
      :variant="variant" 
      dismissible
      >
      {{ errMsg }}
    </b-alert>
    <b-modal
      title="¿Deseas tomar este servicio?"
      v-model="showPickupServiceModal"
    >
      <b-row v-if="service != null">
        <b-col lg="6">
          <strong>Id:</strong> {{service.id}}
        </b-col>
        <b-col lg="12">
          <CIcon name="cilClock" size="sm" height="48" alt="Logo" /> 
          {{service.startDateTime | formatDateTime3}} - {{service.endDateTime | formatDateTime3}}
        </b-col>
        <b-col lg="6">
          <CIcon name="cilUser" size="sm" height="48" alt="Logo" /> 
          {{service.client.name}}
        </b-col>
        <b-col lg="6">
          <CIcon name="cilDog" size="sm" height="48" alt="Logo" />
          {{service.pet.name}}
        </b-col>
        <b-col lg="6">
          <CIcon name="cilHome" size="sm" height="48" alt="Logo" />
          {{service.client.address}}
        </b-col>
        <b-col lg="6">
          <strong>Barrio:</strong>
          {{service.client.neighborhood}}
        </b-col>
        <b-col lg="6">
          <h5>
            {{serviceTypeInitials(service.obs_client)}}
          </h5>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-row>
            <b-col 
              lg="6" 
              sm="6"
              v-if="service != null && userId != null"
              style="text-align: center; width: 50%">
              <b-button 
                size="md" 
                variant="success" 
                @click="takeService">
                Si, tomar
              </b-button>
            </b-col>
            <b-col lg="6" sm="6"
              style="text-align: center; width: 50%">
              <b-button 
                size="md" 
                variant="secondary" 
                @click="hideModal"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name:"alertModal",
  props: ['service', 'showPickupServiceModal'],
  data(){
    return {      
      flagErr: false,
      errMsg: "",
      variant: "success",
      userId: null,
      userProfile: null,
    }
  },
  created(){
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    if(this.userProfile != null){
      this.userId = this.userProfile.usuario.id
    }
  },
  methods: {
    takeService(){
      console.log('takeService: ', this.service)
      let payload = {
        serviceId: this.service.id,
        walkerId: this.userId,
        validateIsUserNull: true,
      };
      console.log('payload: ', payload)
      let assignWalkerToService = this.$firebase.functions().httpsCallable("assignWalkerToService");
      assignWalkerToService(payload).then(res => {
          console.log(res.data);
        let data = res.data.data;
        if (data != null){
          this.service = null;
          this.flagErr = true;
          this.errMsg = data.message;
          this.variant = "success";
          this.fetchServices();
          this.$store.commit("defShowPickupServiceModal", false);
        } 
        else {
          //The service already has a provider
          this.service = null;
          this.flagErr = true;
          this.errMsg = res.data.error;
          this.variant = "danger";
          this.$store.commit("defShowPickupServiceModal", false);
        }
       
      }).catch(error => {
        console.log(error);
        this.flagErr = true;
        this.errMsg = error;
        this.variant = "danger";
      });
    },
    hideModal(){
      this.$store.commit('defShowPickupServiceModal', false)
    },
    fetchServices(){
      let startDate = moment(new Date()).format('YYYY-MM-DDT')+'00:00:00.000Z'
      let endDate = moment(startDate).add(15, 'days').format('YYYY-MM-DDT') + '23:59:59.000Z'
      let payload = {
        userId: this.userProfile.usuario.id,
        startDate,
        endDate,
      }
      this.service = null
      this.$store.dispatch("fetchProviderServicesAvailable", payload).then(data => {
        // if(data){
        //   this.events = data.events
        // }
      }).catch((error) => {
        console.log(error)
      })     
    },
    serviceTypeInitials(obs_client){
      let a = obs_client.split(' ')
      if (a.length > 1) {
        let serviceType = a[0]
        if(a[0] == 'P'){
          serviceType = 'Paseo'
        } else if (a[0] == 'A'){
          serviceType = 'Adiestramiento'
        } else {
          serviceType = a[0]
        }
        return serviceType + ' ' + a[1]
      } else {
        return obs_client
      }
    }
  }

};
</script>

<style lang="scss" scoped></style>
