<template>
  <div>
    <Modal
      :showPickupServiceModal="showPickupServiceModal"
      :service="serviceToTake"/>
    <b-row>
      <b-col lg="5" offset-lg="1">
        <h4>
          <strong>Fecha: </strong> {{date | formatDateTime1}},  
          <strong>Servicios: {{services.length}}</strong>         
        </h4>
      </b-col>
      <b-col lg="5" style="text-align: right">
        <b-button @click="goBack">
            Atrás
        </b-button>
      </b-col>      
    </b-row>
    <br>
    <b-row>
      <b-col lg="10" offset-lg="1" v-for="(service, i) in services" v-bind:key="service.id">
        <CCard :border-color="borderColor(service.walker)">
          <CCardBody>
            <b-row>
              <b-col lg="6">
                #{{i+1}}
              </b-col>
              <b-col lg="6">
                <strong>Id:</strong> {{service.id}}
              </b-col>
              <b-col lg="12">
                <CIcon name="cilClock" size="sm" height="48" alt="Logo" /> 
                {{service.startDateTime | formatDateTime3}} - {{service.endDateTime | formatDateTime3}}
              </b-col>
              <b-col lg="6">
                <CIcon name="cilUser" size="sm" height="48" alt="Logo" /> 
                {{service.client.name}}
              </b-col>
              <b-col lg="6">
                <CIcon name="cilDog" size="sm" height="48" alt="Logo" />
                {{service.pet.name}}
              </b-col>
              <b-col lg="6">
                <CIcon name="cilHome" size="sm" height="48" alt="Logo" />
                {{service.client.address}}
              </b-col>
              <b-col lg="6">
                <strong>Barrio:</strong>
                {{service.client.neighborhood}}
              </b-col>
              <b-col lg="6">
                <h5>
                  {{serviceTypeInitials(service.obs_client)}}
                </h5>
              </b-col>
              <b-col 
                lg="6"
                v-if="service.client.location!=null">
                <a 
                target="_blank"
                @click="openMap(service.client.location)"
                class="btn btn-secondary"
                >Ubicación</a>
              </b-col>
            </b-row>
          </CCardBody>
          <CCardFooter v-if="service.walker == null">
            <b-col 
              lg="12"
              style="text-align: center"
            >
              <b-button 
                @click="pickupService(service)"
                variant="info"
                >
                Tomar servicio
              </b-button>
            </b-col>
          </CCardFooter>
        </CCard>
        
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import Modal from './AlertModal'
export default {
  name: "provider-calendar-list",
  components: { Modal },
  data(){
    return {
      date: null,
      services: null,
      showPickupServiceModal: false,
      serviceToTake: null
    }
  },
  created() {
    // console.log(this.$route)
    if ("date" in this.$route.params) {
      let params = this.$route.params;
      this.date = moment(params.date).format('YYYY-MM-DD')
      // console.log(this.date)
    }
    if ("services" in this.$route.params) {
      let params = this.$route.params;
      // console.log('services: ', params)
      this.services = params.services
      this.services = this.services.filter(
      service => moment(service.startDateTime).format('YYYY-MM-DD') == this.date)
    }
    this.watchs()
  },
  methods: {
    borderColor(walker){
      let variant = 'success'
      if(walker == null)
       variant = 'warning'
      return variant
    },
    openMap (location){
      let mapsUrl = 'https://www.google.com/maps/place/'
      let fullUrl = mapsUrl + location.lat + ',' + location.lng
      window.open(fullUrl, '_blank')
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defShowPickupServiceModal") {
          this.showPickupServiceModal = this.$store.getters.showPickupServiceModal
        }
        if (mutation.type === "defCalendarProviderServices") {
          this.serviceToTake = null
          this.services = this.$store.getters.calendarProviderServices.filter(
              service => moment(service.startDateTime).format('YYYY-MM-DD') == this.date)
          
        }
      });
    },
    pickupService(service){
      // console.log('pickupService: ', service)
      this.serviceToTake = service
      this.showPickupServiceModal = true
      
    },
    goBack(){
      this.$router.push({name: 'provider-calendar'})
    },
    serviceTypeInitials(obs_client){
      let a = obs_client.split(' ')
      if (a.length > 1) {
        let serviceType = a[0]
        if(a[0] == 'P'){
          serviceType = 'Paseo'
        } else if (a[0] == 'A'){
          serviceType = 'Adiestramiento'
        } else {
          serviceType = a[0]
        }
        return serviceType + ' ' + a[1]
      } else {
        return obs_client
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
